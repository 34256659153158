import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";
import { createMJHttp } from "@/api/MJRequest";
const axios = createHttp(appConfig.base2GUrl);
const SystemAxios = createMJHttp(appConfig.SystemBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);

// 保单列表
export const policyList = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/page",
    method: "get",
    loading: true,
    params,
  });
};

// 保单列表
export const nonPolicyList = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/page",
    method: "get",
    loading: true,
    params,
  });
};

// 保单列表-模糊搜索
export const policyFastPage = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/fastPage",
    method: "get",
    loading: true,
    params,
  });
};

// 保单详情
export const getPolicyDetail = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/getDetail",
    method: "get",
    loading: true,
    params,
  });
};

// 保单详情-批单使用
export const getApprovalPolicyDetail = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/getDetail",
    method: "get",
    loading: true,
    params,
  });
};

// 保单详情 - 非公司
export const getNonPolicyDetail = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/getDetail",
    method: "get",
    loading: true,
    params,
  });
};

// 批量获取字典
export const dictionaryBatch = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/dict/dictionaryBatch",
    method: "get",
    params,
  });
};

// 承保方案管理列表
export const insuredList = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/page",
    method: "get",
    loading: true,
    params,
  });
};

// 删除承保方案
export const insuredListDel = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/delete",
    method: "get",
    loading: true,
    params,
  });
};

// 获取添加人员列表
export const getCreateUser = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/getCreateUser",
    method: "get",
    params,
  });
};

//导出承保方案
export const exportInsured = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/export",
    method: "get",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    params,
  });
};

//承保方案-保单套用
export const listScheme = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/listScheme",
    method: "get",
    params,
  });
};
//获取下属成员
export const getUserByCharge = (params) => {
  return SystemAxios.axios({
    url: "/blade-user/getUserByCharge",
    method: "get",
    params,
  });
};

//获取批单列表
export const getCorrectionData = (data, current, size) => {
  return SystemAxios.axios({
    url: `/ins-policy/rider/pageList?current=${current}&size=${size}`,
    method: "POST",
    loading: true,
    data,
  });
};
//上传电子批单
export const uploadCorrectionOrder = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/rider/electronicOrder",
    method: "POST",
    loading: true,
    data,
  });
};
//批单申请书下载
export const downloadCorrectionDoc = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/rider/downWordFile",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

//删除批单
export const deletCorrection = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/rider/delRider",
    method: "POST",
    loading: true,
    data,
  });
};

//取消批单
export const cancelCorrection = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/rider/cancelRider",
    method: "POST",
    loading: true,
    data,
  });
};
// 获取险种名称列表
export const getDictionAry = (params) => {
  return SystemAxios.axios({
    url: "/blade-system/dict/dictionaryBatch",
    method: "get",
    loading: true,
    params,
  });
};
// 承保方案-分类总数
export const getSchemeTotal = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/total",
    method: "get",
    loading: true,
    params,
  });
};

// 添加承保方案
export const insuredAdd = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/add",
    method: "post",
    loading: true,
    data,
  });
};

// 编辑承保方案
export const insuredEdit = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/edit",
    method: "post",
    loading: true,
    data,
  });
};

// 获取承保方案详情
export const getInsuredDetail = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/list",
    method: "get",
    loading: true,
    params,
  });
};
//询价单列表查询
export const enquiryList = (params, data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/list",
    method: "POST",
    loading: true,
    data,
    params,
  });
};
//批改历史
export const getCorrectionHistory = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/rider/riderHistory",
    method: "GET",
    loading: true,
    params,
  });
};

//批单导出
export const exportCorrection = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/rider/export",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

// 下载标的信息模板
export const downloadSubjectTemplate = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policySubject/downloadTemplate",
    method: "get",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    params,
  });
};

// 导入标的信息
export const subjectImport = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policySubject/parse",
    method: "post",
    loading: true,
    data,
  });
};

// 下载标的信息
export const downloadSubject = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policySubject/export",
    method: "get",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    params,
  });
};

// 导出保单
export const exportPolicyByIds = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/exportByIds",
    method: "get",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    params,
  });
};

// 导出保单
export const nonExportPolicyByIds = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/exportByIds",
    method: "get",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    params,
  });
};

// 导出保单
export const exportPolicy = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/export",
    method: "get",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    params,
  });
};

// 导出保单 - 非公司
export const nonExportPolicy = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/export",
    method: "get",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    params,
  });
};

// 保单识别 - 非公司
export const policyArchiveOcr = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/ocr",
    method: "post",
    loading: true,
    data,
  });
};


// 删除保单
export const policyRemove = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/remove",
    method: "post",
    loading: true,
    data,
  });
};

// 获取保单编辑历史
export const getUpdateRecord = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/getUpdateRecord",
    method: "get",
    params,
  });
};

// 获取保单编辑历史 - 非公司
export const getNonUpdateRecord = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/getUpdateRecord",
    method: "get",
    params,
  });
};

// 获取电子保单
export const getPolicyEP = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/getEP",
    method: "get",
    loading: true,
    params,
  });
};

// 获取电子保单 - 非公司
export const getNonPolicyEP = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/getEP",
    method: "get",
    loading: true,
    params,
  });
};


// 编辑电子保单
export const uploadPolicyEP = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/uploadEP",
    method: "post",
    loading: true,
    data,
  });
};


// 编辑电子保单 - 非公司
export const uploadNonPolicyEP = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/uploadEP",
    method: "post",
    loading: true,
    data,
  });
};


//询价单新增
export const saveEnquiryList = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/save",
    method: "POST",
    loading: true,
    data,
  });
};
//询价临时保存
export const tempSaveEnquiryList = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/saveDraft",
    method: "POST",
    loading: true,
    data,
  });
};

//获取批单详情
export const getCorrectionDetails = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/rider/getDetail",
    method: "GET",
    loading: true,
    params,
  });
};
// 编辑保单
export const policyUpdate = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/update",
    method: "post",
    loading: true,
    data,
  });
};

// 编辑保单 - 非公司
export const nonPolicyUpdate = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive2/update",
    method: "post",
    loading: true,
    data,
  });
};

// 询价单转保单
export const policyArchiveEtp = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/etp",
    method: "post",
    loading: true,
    data,
  });
};

// 查询该保单号是否存在
export const policyArchiveHasExists = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/hasExists",
    method: "get",
    params,
  });
};

//根据id删除承保方案
export const deletPolicyPlan = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/scheme/delete",
    method: "GET",
    loading: true,
    params,
  });
};

//根据id删除标的
export const deletPolicySubject = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policySubject/deleteById",
    method: "GET",
    loading: true,
    params,
  });
};

//根据id删除参与人
export const deletInsPolicyArchivePlayers = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/insPolicyArchivePlayers/deleteById",
    method: "GET",
    loading: true,
    params,
  });
};

//保单批改
export const policyCorrection = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/rider/changeInfo",
    method: "POST",
    loading: true,
    data,
  });
};

//询价单删除
export const delEnquiryList = (ids) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEnquiryArchive/delete/${ids}`,
    method: "GET",
    loading: true,
  });
};

// 营业执照识别
export const businessLicense = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/ocr/businessLicense",
    method: "post",
    loading: true,
    data,
  });
};

// 身份证识别
export const businessIdentityCard = (data) => {
  return SystemAxios.axios({
    url: "/blade-system/ocr/ID",
    method: "post",
    loading: true,
    data,
  });
};

// 下载保单导入模板
export const downloadPolicyTemplate = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/downloadTemplate",
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};
// 下载托单导入模板
export const downloadHostingTemplate = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/trusteeship/downloadTemplate",
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};
// 导入保单
export const policyArchiveParse = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/parse",
    method: "post",
    loading: true,
    data,
  });
};
// 导入托单
export const HostingParse = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/trusteeship/import",
    method: "post",
    loading: true,
    data,
  });
};

// 询价单详情
export const insEnquiryDetail = (id) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/find/" + id,
    method: "get",
    loading: true,
  });
};

// 询价单详情
export const insNewEnquiryDetail = (id) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/findDetail/" + id,
    method: "get",
    loading: true,
  });
};

// 转投保单
export const changeToInsurance = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/changeToInsurance",
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};

// 询价单导入模板
export const downloadEnquiryTemplate = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/downloadTemplate",
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};

// 导入询价单
export const importEnquireArchive = (params, data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/importEnquireArchive",
    method: "post",
    loading: true,
    params,
    data,
  });
};

// 询价单导出
export const exportEnquiryByIds = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/exportByIds",
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};

// 询价单导出 - 条件
export const exportEnquiryExport = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/export",
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};

// 询价单模糊搜索
export const insEnquiryArchiveFast = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/fast",
    method: "post",
    loading: true,
    params,
  });
};

//续保管理分页
export const renewalList = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/renewal/list`,
    method: "GET",
    loading: true,
    params,
  });
};

//续保管理模糊分页
export const renewalListFast = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/renewal/fuzzyQuery`,
    method: "GET",
    loading: true,
    params,
  });
};

// 续保管理导出
export const exportRenewalByIds = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/renewal/export",
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};

//批单详情
export const modificationDetails = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/rider/getCorrectDetail`,
    method: "GET",
    loading: true,
    params,
  });
};

//电子批单回显
export const electronShowBack = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/rider/getHistoryDetail`,
    method: "GET",
    loading: true,
    params,
  });
};

// 搜索参与人信息
export const selectPlayers = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insPolicyArchivePlayers/selectPlayers`,
    method: "GET",
    params,
  });
};

// 获取参与人信息
export const selectPlayerById = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insPolicyArchivePlayers/selectPlayerById`,
    method: "GET",
    params,
  });
};

//生成doc报价单
export const generateQuotationSheet = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEnquiryArchive/changeToQuotation`,
    method: "GET",
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};

//搜索客户列表
export const pageMyCustomer = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/pageMyCustomer`,
    method: "GET",
    params,
  });
};

//数据绑定给用户-保单
export const policyBindUser = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/policyArchive/bindUser`,
    method: "POST",
    data,
  });
};

//数据绑定给用户-询价单
export const enquiryBindUser = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEnquiryArchive/bindUser`,
    method: "POST",
    data,
  });
};

// 推荐人列表
export const referrerPage = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/customer/referrerPage`,
    method: "GET",
    params,
  });
};

// 托管单列表
export const trusteeshipPage = (data, params) => {
  return SystemAxios.axios({
    url: `/ins-policy/trusteeship/pageList`,
    method: "post",
    loading: true,
    params,
    data,
  });
};

// 删除托管单
export const trusteeshipDel = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/trusteeship/batchDelete`,
    method: "post",
    loading: true,
    params,
  });
};

// 处理托管单
export const trusteeshipBatchProcess = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/trusteeship/batchProcess`,
    method: "post",
    loading: true,
    params,
  });
};

// 导出托管单
export const trusteeshipExport = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/trusteeship/export`,
    method: "post",
    responseType: "blob",
    timeout: 1800000,
    loading: true,
    params,
  });
};

// 添加/编辑托管单
export const trusteeshipSubject = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/trusteeship/subject`,
    method: "post",
    loading: true,
    data,
  });
};

// 回去托管单数量
export const trusteeshipStatusStatistics = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/trusteeship/statusStatistics`,
    method: "post",
    data,
  });
};

// 托管单详情
export const trusteeshipDetails = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/trusteeship/details`,
    method: "get",
    loading: true,
    params,
  });
};

// 复制询价单
export const copyEnquireArchive = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEnquiryArchive/copyEnquireArchive`,
    method: "get",
    loading: true,
    params,
  });
};

// 证件号查重
export const insEnquiryArchiveRepeat = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEnquiryArchive/repeat`,
    method: "get",
    params,
  });
};

// 证件号重复列表 询价单
export const insEnquiryArchiveRepeatPage = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEnquiryArchive/repeat/page`,
    method: "get",
    params,
  });
};

// 获取保单审核列表
export const policyProcessPage = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/policyProcess/page`,
    method: "get",
    loading: true,
    params,
  });
};

// 导出 保单审核列表
export const policyProcessExport = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/policyProcess/export`,
    method: "get",
    loading: true,
    responseType: "blob",
    timeout: 1800000,
    params,
  });
};

// 审批保单审核
export const policyProcessApproval = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/policyProcess/approval`,
    method: "post",
    loading: true,
    data,
  });
};

// 撤回 保单审核
export const policyProcessRevoke = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/policyProcess/revoke`,
    method: "get",
    loading: true,
    params,
  });
};

// 获取保单审核节点记录
export const policyProcessGetNodeRecord = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/policyProcess/getNodeRecord`,
    method: "get",
    loading: true,
    params,
  });
};

// 获取询价单数据统计
export const getEnquireArchiveDataStatistics = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEnquiryArchive/getEnquireArchiveDataStatistics`,
    method: "POST",
    params,
  });
};

// 获取保单数据统计
export const getDataStatistics = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/policyArchive/getDataStatistics`,
    method: "get",
    params,
  });
};

// 非公司获取保单数据统计
export const nonGetDataStatistics = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/policyArchive2/getDataStatistics`,
    method: "get",
    params,
  });
};

// 获取批单数据统计
export const getRiderDataStatistics = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/rider/getRiderDataStatistics`,
    method: "POST",
    data,
  });
};

// 获取续保数据统计
export const getRenewalDataStatistics = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/renewal/getRenewalDataStatistics`,
    method: "get",
    params,
  });
};

// 获取Banner列表
export const getBannerList = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insBanner/getBannerList`,
    method: "post",
    loading: true,
    params,
  });
};
// 条款配置分页
export const getAllocationPage = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/termConfiguration/page`,
    method: "post",
    loading: true,
    data,
  });
};
//获取条款文件列表
export const getClauseList = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/termsDocuments/page`,
    method: "POST",
    loading: true,
    data,
  });
};
// 条款配置分类总数
export const getAllocationPageTotal = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/termConfiguration/pageTotal`,
    method: "get",
    loading: true,
    params,
  });
};
//添加条款文件
export const addClauseLDoc = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/termsDocuments/addOrUpdate`,
    method: "POST",
    loading: true,
    data,
  });
};
//删除条款文件
export const deletClauseLDoc = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/termsDocuments/delete`,
    method: "POST",
    loading: true,
    data,
  });
};

// 设置测算Banner
export const saveBanner = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/insBanner/saveBanner`,
    method: "POST",
    loading: true,
    data,
  });
};
//条款文件勾选导出
export const exportClauseLDoc = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/termsDocuments/exportByIds`,
    method: "POST",
    loading: true,
    responseType: "blob",
    data,
  });
};

// 删除测算Banner
export const deleteBanners = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insBanner/deleteBanners`,
    method: "get",
    loading: true,
    params,
  });
};
// 查询保险公司下所有条款文件
export const termsDocumentsByCompany = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/termsDocuments/termsDocumentsByCompany`,
    method: "get",
    loading: true,
    params,
  });
};

// 获取设备种类列表
export const getEquipmentTypePageList = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEquipmentType/getEquipmentTypePageList`,
    method: "POST",
    loading: true,
    params,
  });
};
//条款配置添加修改
export const addOrUpdateConfiguration = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/termConfiguration/addOrUpdate`,
    method: "POST",
    loading: true,
    data,
  });
};
//条款配置删除
export const delConfiguration = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/termConfiguration/delete`,
    method: "POST",
    loading: true,
    data,
  });
};

// 设置设备种类
export const saveInsEquipmentType = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEquipmentType/saveInsEquipmentType`,
    method: "POST",
    loading: true,
    data,
  });
};

// 删除设备种类
export const deleteInsEquipmentType = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEquipmentType/deleteInsEquipmentType`,
    method: "get",
    loading: true,
    params,
  });
};
// 条款配置详情
export const getConfigurationDetail = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/termConfiguration/details`,
    method: "get",
    loading: true,
    params,
  });
};

// 获取品牌列表
export const getBrandInfoPageList = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insBrandInfo/getBrandInfoPageList`,
    method: "POST",
    loading: true,
    params,
  });
};

//条款配置导出
export const exportAllocation = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/termConfiguration/exportByIds",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

//测算记录分页
export const getCalculateRecord = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/calculate/pageList`,
    method: "POST",
    loading: true,
    data,
  });
};

// 设置品牌
export const saveInsBrandInfo = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/insBrandInfo/saveInsBrandInfo`,
    method: "POST",
    loading: true,
    data,
  });
};
//测算记录删除
export const delCalculateRecord = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/calculate/del`,
    method: "POST",
    loading: true,
    data,
  });
};

// 删除品牌
export const deleteInsBrandInfo = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insBrandInfo/deleteInsBrandInfo`,
    method: "get",
    loading: true,
    params,
  });
};
//测算记录导出
export const exportCalculateRecord = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/calculate/download",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

//测算记录category
export const categoryCalculateRecord = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/calculate/getCountOrderByCategory`,
    method: "get",
    loading: true,
    params,
  });
};

//客户端设备类型列表
export const getClientEquipmentTypeList = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEquipmentType/getEquipmentTypeList`,
    method: "GET",
    params,
  });
};

//查询保险公司下所有条款配置
export const getInsuranceCompanyClause = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/termConfiguration/termConfigurationByCompany`,
    method: "GET",
    params,
  });
};
//添加承保方案
export const addInsuranceScheme = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insureProgramme/addOrUpdate",
    method: "POST",
    loading: true,
    data,
  });
};
//查看承保方案详情
export const getInsuranceSchemeDeatil = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insureProgramme/details`,
    method: "GET",
    params,
  });
};

//承保方案列表
export const getInsuranceSchemeList = (data, params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insureProgramme/page?current=${params.current}&size=${params.size}`,
    method: "POST",
    data,
  });
};

//承保方案勾选导出
export const exportInsuranceScheme = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insureProgramme/exportByIds",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

//删除承保方案
export const deletInsuranceScheme = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insureProgramme/delete",
    method: "POST",
    loading: true,
    data,
  });
};

//转询价单回显默认值
export const echoEnquiry = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/calculate/convertInquiry",
    method: "POST",
    loading: true,
    data,
  });
};

//推荐方案添加
export const addRecommendCalculate = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/recommendation/addOrUpdate",
    method: "POST",
    loading: true,
    data,
  });
};

//客户端可用推荐方案
export const getRecommendCalculateList = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/recommendation/selectClientDisplay`,
    method: "GET",
    params,
  });
};
//客户端可用推荐方案列表
export const getRecommendList = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/recommendation/page`,
    method: "POST",
    data,
  });
};

//客户端可用推荐方案列表勾选导出
export const exportRecommendList = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/recommendation/exportByIds`,
    method: "POST",
    data,
  });
};
//客户端可用推荐方案删除
export const deletRecommendByIds = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/recommendation/delete`,
    method: "POST",
    data,
  });
};

//分销代理人-查代理人端显示的方案
export const insureProgrammeByAgent = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insureProgramme/insureProgrammeByAgent`,
    method: "GET",
    params,
  });
};

//pc端测算记录表头统计信息
export const diyTitles = (data) => {
  return SystemAxios.axios({
    url: `/ins-policy/calculate/diyTitles`,
    method: "POST",
    data,
  });
};

// 可见方案分页-批量操作可见方案
export const insureProgrammeByAgentPage = (data, params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insureProgramme/insureProgrammeByAgentPage`,
    method: "POST",
    loading: true,
    data,
    params,
  });
};

//根据保险类型获取承保方案
export const getInsureProgrammeByCategory = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insureProgramme/insureProgrammeByCompany`,
    method: "GET",
    loading: true,
    params,
  });
};

//根据保险类型获取承保条款
export const getInsureClauseByCategory = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/termConfiguration/termConfigurationByCategory`,
    method: "GET",
    loading: true,
    params,
  });
};

//获取业务代理人列表
export const getUpstreamAgentList = (params) => {
  return SystemAxios.axios({
    url: `/blade-user/agent/getAgent`,
    method: "GET",
    loading: true,
    params,
  });
};

//获取承保方案列表分类数量
export const getInsuranceSchemeTypeCount = (params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insureProgramme/pageTotal`,
    method: "GET",
    params,
  });
};

//获取条款文件列表分类数量
export const getInsuranceDocTypeCount = (data, params) => {
  return SystemAxios.axios({
    url: `/ins-policy/termsDocuments/pageTotal?current=${params.current}&size=${params.size}`,
    method: "POST",
    data,
  });
};
//投保确认列表
export const getConfirmPage = (data, params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insConfirmation/getConfirmPage`,
    method: "POST",
    data,
  });
};
//投保确认删除
export const ConfirmDel = (data, params) => {
  return SystemAxios.axios({
    url: `/ins-policy/insConfirmation/deleteConfirmations?ids=${data.ids}`,
    method: "GET",
  });
};

//投保确认列表导出
export const exportInsConfirmation = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insConfirmation/export",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

//线下投保确认
export const insureOfflineConfirmation = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/insConfirmation/verifyConfirmationToOffLine",
    method: "GET",
    loading: true,
    params,
  });
};

//投保确认详情
export const insConfirmationDetail = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/insConfirmation/getConfirmationDetail",
    method: "POST",
    data,
  });
};

//线上投保生成投保单带签名投保确认单
export const generateInsuranceAndImage = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/insEnquiryArchive/generateInsuranceAndImage",
    method: "GET",
    loading: true,
    responseType: "blob",
    filename: "保单文件",
    filetype: "pdf",
    params,
  });
};

// 保单确认见费
export const countersignSeeFee = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/countersignSeeFee",
    method: "GET",
    loading: true,
    params,
  });
};

// 绩效规则列表
export const performanceRulePage = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/performanceRulePage",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效规则 新增编辑
export const redactRule = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/redactRule",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效规则 删除规则
export const performanceRemoveByIds = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/removeByIds",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效规则列表 导出
export const exportPerformanceRule = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/exportPerformanceRule",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

// 获取方案名称列表
export const performancePlanPage = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/planPage",
    method: "GET",
    params,
  });
};

// 获取用户名称列表
export const performanceInsuredPage = (params) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/insuredPage",
    method: "GET",
    params,
  });
};

// 绩效 员工列表
export const userPerformancePage = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/userPerformancePage",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效 员工详情
export const getUserPerformance = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/getUserPerformance",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效 员工列表 删除
export const removePerformanceToMonth = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/removePerformanceToMonth",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效员工列表 导出
export const userPerformanceDownload = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/userPerformanceDownload",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

// 绩效总表 列表
export const performancePage = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/performancePage",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效总表 统计
export const performanceRecordPageTitle = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/performanceRecordPageTitle",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效总表 调整手续费
export const adjustPerformance = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/adjustPerformance",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效总表 导出
export const performanceDownload = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/performanceDownload",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
};

// 绩效总表 业务员下拉数据
export const salesmanSelectList = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/salesmanSelectList",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效总表 代理人下拉数据
export const agentSelectList = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/agentSelectList",
    method: "POST",
    loading: true,
    data,
  });
};

// 绩效总表 出单类型下拉数据
export const outOrderTypeList = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performance/outOrderTypeList",
    method: "POST",
    loading: true,
    data,
  });
};

// 转询价单为草稿
export const convertInsEnquiryToDraft = (ids) => {
  return SystemAxios.axios({
    url: `/ins-policy/insEnquiryArchive/shiftDraft/${ids}`,
    method: "GET",
    loading: true,
  });
};


//保单批改校验业务员状态
export const checkSalesmanStatus = (params) => {
  //salesmanId
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/checkSalesman",
    method: "GET",
    loading: true,
    params,
  });
};

//询价单提交是否要输入业务推荐费
export function OrderReferralSwitch(data) {
  //cardNoList、projectId、programmeName、deptId
  return SystemAxios.axios({
    url: "/ins-policy/performance/getInquiryOrderReferralSwitch",
    method: "POST",
    loading: true,
    data,
  });
}
// 标的证件识别
export const certificateObject = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/policyArchive/recognizeDrivingLicense",
    method: "POST",
    loading: true,
    data,
  });
};
